<template>
  <div>
    <!-- <addService :services="ressources" /> -->
    <h3 class="flix-html-h3">
      {{ $t('message.connect', { name: $tc('message.calendar', 2) }) }} ({{
        $tc('message.ressources', 2)
      }})
    </h3>
    <div class="flix-form-group">
      <b-flix-alert variant="info"
        >{{ $t('message.ressourcesInfo') }}</b-flix-alert
      >
    </div>
    <flix-loader v-if="!loaded"></flix-loader>
    <div v-if="loaded">
      <transition-group name="flixFadeIn" v-if="ressources.length">
        <div
          v-for="(ressource, index) in ressources"
          :key="ressource.ID"
          class="flix-list-group"
        >
          <div class="flix-list-group-item flix-active">
            {{ $tc('message.ressources', 1) }} # {{ index + 1 }}
            <div class="flix-pull-right">
              <a
                href="#"
                @click.prevent="setRemove(index)"
                class="flix-html-a flix-text-danger"
                ><flixIcon :id="'bin'"
              /></a>
            </div>
          </div>
          <div class="flix-list-group-item">
            <div class="flix-row">
              <div class="flix-col-md-10">
                <div class="flix-form-group">
                  <flixIcon class="icon" :id="'git-compare'" />
                  <input
                    @change="setSave(index)"
                    type="text"
                    spellcheck="false"
                    v-model="ressource.title"
                    class="flix-form-control"
                  />
                </div>
              </div>
              <div class="flix-col-md-2">
                <div class="flix-form-group">
                  <ressourcesColors :ressource="ressource" />
                </div>
              </div>
            </div>
            <div class="flix-form-group">
              <flixIcon class="icon" :id="'info-sign'" />
              <input
                @change="setSave(index)"
                type="text"
                spellcheck="false"
                v-model="ressource.description"
                class="flix-form-control"
              />
            </div>
            <div class="flix-row">
              <div class="flix-col-md-12">
                <div class="flix-form-group">
                  <ressourcesCalendars :ressource="ressource" />
                </div>
              </div>
            </div>
            <div class="flix-form-group">
              <flixIcon class="icon" :id="'group'" />
              <h4 class="flix-html-h4" style="margin: 0; display: inline-block">
                {{
                  $t('message.share', {
                    name: $tc('message.ressources', 1) + ' ' + ressource.title
                  })
                }}
              </h4>
            </div>
            <div class="flix-form-group">
              <ressourcesCategory :ressources="ressources" :index="index" />
            </div>
            <div class="flix-form-group">
              <ressourceEmbed :id="'ressource_' + ressource.ID" />
            </div>
          </div>
        </div>
      </transition-group>
      <div class="flix-form-group">
        <addBtn
          :onClick="
            () => {
              setAdd()
            }
          "
        >
          <template v-slot:text>
            {{ $t('message.add', { name: $tc('message.ressources', 1) }) }}
          </template>
        </addBtn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    // ressourcesAdd () { return import('@/components/ressources/add') },
    ressourcesCategory: () => import('@/components/ressources/categories'),
    ressourcesColors: () => import('@/components/ressources/colors'),
    ressourcesCalendars: () => import('@/components/ressources/calendars'),
    ressourceEmbed: () => import('@/components/embed/menu')
  },
  props: {},
  data() {
    return {
      services: [],
      ressources: [],
      loaded: false
    }
  },
  methods: {
    setRemove(index) {
      var send = {
        user: this.$store.getters.user.md5_id,
        data: { ID: this.ressources[index].ID }
      }
      this.$flix_post({
        url: 'ressources/delete',
        data: send,
        callback: (ret) => {
          this.ressources.splice(index, 1)
        }
      })
    },
    setSave(index) {
      var send = {
        user: this.$store.getters.user.md5_id,
        ID: this.ressources[index].ID,
        data: this.ressources[index]
      }
      this.$flix_post({
        url: 'ressources/save',
        data: send,
        callback: (ret) => {}
      })
    },
    setAdd() {
      var ID = new Date().getTime()
      var data = {
        ID: '',
        color: this.$store.getters.colors[0],
        title: this.$tc('message.ressources', 1) + ' #' + ID,
        description: ''
      }
      var send = {
        user: this.$store.getters.user.md5_id,
        ID: '',
        data: data
      }
      this.$flix_post({
        url: 'ressources/save',
        data: send,
        callback: (ret) => {
          this.$store.dispatch('getRessources', (data) => {
            this.ressources = data
          })
        }
      })
    }
  },
  mounted() {
    this.loaded = false
    this.$store.dispatch('getRessources', (data) => {
      this.ressources = data
      this.loaded = true
    })
  }
}
</script>
<style lang="sass" scoped>
.icon
  font-size: 30px
  margin-right: 10px
.flix-form-control
  display: inline-block
  width: calc(100% - 50px)
.flix-active, .flix-active:hover
  background-color: lightgrey
  border: grey
  color: #717C80
</style>
